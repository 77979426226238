import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Switch
} from '@nextui-org/react'
import { sendDataUpdate, useTelegram } from '../../../../fns/useTelegram'
import { useEffect, useRef, useState } from 'react'
import destination from '../../../../assets/point.svg'
import union from '../../../../assets/date.svg'
import clock from '../../../../assets/clock.svg'
import destinationUnactive from '../../../../assets/point-unactive.svg'
import unionUnactive from '../../../../assets/date-unactive.svg'
import clockUnactive from '../../../../assets/clock-unactive.svg'
import arrowRight from '../../../../assets/arrow-right.svg'
import arrowRightUnactive from '../../../../assets/arrow-right-unactive.svg'
import { formatDate, formatTime } from '../Prepare/Prepare'

export default function Truck({
  truckDataProps,
  setActivePage,
  setSelectedTruck
}) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onOpenChange: onOpenChangeConfirm
  } = useDisclosure()

  const { tg } = useTelegram()

  const switchRef = useRef()

  const [truckData, setTruckData] = useState(truckDataProps)
  const [bids, setBids] = useState(null)

  const handleLoadBids = async () => {
    const headers = new Headers()

    headers.append('accept', 'application/json')
    if (process.env.REACT_APP_ENV === 'production') {
      headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
    }

    const res = await fetch(
      `${process.env.REACT_APP_HOST}/webapp/directions/bid_details/?truck_id=${truckData.id}&loc_from=${truckData.loc_from}`,
      {
        method: 'GET',
        headers: headers
      }
    )
      .then((res) => res.json())
      .catch(() => ({ status: 'error', states: {} }))

    setBids(res.states)
  }

  const handleMapActive = (truck) => {
    if (truck.active === 0) {
      return {
        color: 'rgb(0, 0, 0, 0.5)',
        backgroundColor: 'rgba(182, 182, 184, 0.3)'
      }
    }

    switch (truckData.upd_status?.toLowerCase()) {
      case 'updated': {
        return {
          color: 'rgb(36, 168, 65)',
          backgroundColor: 'rgba(36, 168, 65, 0.25)'
        }
      }

      case 'not updated': {
        return {
          color: 'rgb(225, 2, 2)',
          backgroundColor: 'rgba(225, 2, 2, 0.25)'
        }
      }

      case 'disabled': {
        return {
          color: 'rgb(225, 2, 2)',
          backgroundColor: 'rgba(225, 2, 2, 0.25)'
        }
      }
    }

    return {
      title: 'Unknown',
      color: 'rgb(225, 2, 2)',
      backgroundColor: 'rgba(225, 2, 2, 0.25)'
    }
  }

  useEffect(() => {
    handleLoadBids()
  }, [])

  return (
    <>
      <div
        className="flex flex-col rounded-md shadow-custom border-l-8 border-solid"
        style={{
          borderColor: handleMapActive(truckData).color
        }}
      >
        <div
          onClick={() => {
            setSelectedTruck(JSON.parse(JSON.stringify(truckData)))
            setActivePage(1)
          }}
        >
          <div className="flex flex-row justify-between py-2 px-4">
            <div className="flex flex-col justify-between items-start">
              <div
                className={
                  'text-base font-bold text-color-text ' +
                  (truckData.active === 0 ? 'text-unactive' : '')
                }
              >
                {truckData?.driver || ''} -{' '}
                <span
                  className={
                    'text-base font-medium text-color-text mt-2 capitalize text-center ' +
                    (truckData.active === 0 ? 'text-unactive' : '')
                  }
                >
                  {truckData?.type.toLowerCase() || ''}
                </span>
              </div>

              <div
                className={`bg-opacity-25 text-base font-bold rounded capitalize`}
                style={{
                  color: handleMapActive(truckData).color
                }}
              >
                {truckData.upd_status || ''}
              </div>
            </div>
            <Switch
              defaultSelected={!!truckData.active || false}
              color="primary"
              isSelected={!!truckData.active || false}
              ref={switchRef}
              classNames={{
                wrapper: 'mr-0'
              }}
              onChange={(e) => {
                if (e.target.checked) {
                  setTruckData({
                    ...truckData,
                    active: 1
                  })

                  sendDataUpdate(
                    {
                      truck: {
                        id: truckData.id,
                        active: 1
                      }
                    },
                    truckData.id
                  ).catch((e) => {
                    console.log('Error update status:', e.message)
                  })
                } else {
                  onOpenConfirm()
                }
              }}
            />
          </div>
          <hr />
          <div className="grid grid-rows-2 py-2 px-4 gap-0.5">
            <div className="flex flex-row">
              <img
                src={truckData.active === 0 ? destinationUnactive : destination}
              />
              <div>
                <div
                  className={
                    'text-color-text text-base font-bold ml-1 ' +
                    (truckData.active === 0 ? 'text-unactive' : '')
                  }
                >
                  {truckData?.address.split(',').slice(1).join(', ') || ''}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex">
                <div className="flex items-center">
                  <img src={truckData.active === 0 ? unionUnactive : union} />
                  <div
                    className={
                      'text-base text-color-text font-medium ml-1 ' +
                      (truckData.active === 0 ? 'text-unactive' : '')
                    }
                  >
                    {formatDate(new Date(truckData.loc_from))}
                  </div>
                </div>
                <div
                  className={
                    'flex items-center ml-5 ' +
                    (truckData.active === 0 ? 'text-unactive' : '')
                  }
                >
                  <img src={truckData.active === 0 ? clockUnactive : clock} />
                  <div
                    className={
                      'text-base text-color-text font-medium ml-1 ' +
                      (truckData.active === 0 ? 'text-unactive' : '')
                    }
                  >
                    {formatTime(new Date(truckData.loc_from))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>

        <div className="flex flex-row justify-between items-center py-3 px-4">
          <div
            onClick={onOpen}
            className="py-0.5 px-0.5 rounded-3xl z-50"
            style={{
              background:
                truckData.active === 0
                  ? '#b6b6b8'
                  : 'linear-gradient(90deg, #3290EC -7.69%, #911893 152.31%)'
            }}
          >
            <div
              className={
                'text-base font-extrabold cursor-pointer active:opacity-50 rounded-3xl px-3 py-0.5 bg-white'
              }
            >
              <div
                style={
                  truckData.active === 0
                    ? {
                        color: '#b6b6b8'
                      }
                    : {
                        background:
                          'linear-gradient(90deg, #3290EC 0%, #911893 156.86%)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                      }
                }
              >
                {truckData.bidcount}/Bids
              </div>
            </div>
          </div>
          <div
            className="flex flex-1 h-full"
            onClick={() => {
              setSelectedTruck(JSON.parse(JSON.stringify(truckData)))
              setActivePage(1)
            }}
          ></div>
          <div
            className="cursor-pointer active:opacity-50"
            onClick={() => {
              setSelectedTruck(JSON.parse(JSON.stringify(truckData)))
              setActivePage(1)
            }}
          >
            <img
              src={truckData.active === 0 ? arrowRightUnactive : arrowRight}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="center"
        classNames={{
          wrapper: 'px-5',
          closeButton: 'mt-2',
          base: 'rounded-md'
        }}
        scrollBehavior={'inside'}
        hideCloseButton
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <div className="text-lg font-bold text-black"></div>
              </ModalHeader>
              <ModalBody>
                {bids &&
                  Object.keys(bids).map((item) => (
                    <div
                      className="flex items-center justify-between pb-5 border-b-1 border-border-color"
                      key={item}
                    >
                      <div className="text-base text-black font-medium">
                        {item}
                      </div>
                      <div className="text-primary font-bold text-base">
                        Bids: {bids[item]}
                      </div>
                    </div>
                  ))}
              </ModalBody>
              <ModalFooter className="justify-center">
                <Button
                  variant="bordered"
                  className="w-full rounded-md border-border-color border-1 text-unactive"
                  size="lg"
                  onClick={onClose}
                >
                  <div
                    className="text-base font-semibold"
                    style={{
                      color: '#0000008C'
                    }}
                  >
                    Back
                  </div>
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenConfirm}
        onOpenChange={onOpenChangeConfirm}
        placement="center"
        classNames={{
          wrapper: 'px-5',
          closeButton: 'mt-2',
          base: 'rounded-md'
        }}
        closeButton={<></>}
        scrollBehavior={'inside'}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="text-center border-b-1">
                <div className="pt-9 pb-4">
                  <div className="text-lg">
                    Are you sure you want to disable{' '}
                    <span className="font-bold">{truckData.plate_number}</span>{' '}
                    driven by{' '}
                    <span className="font-bold">{truckData.driver}</span> ?
                  </div>
                  <div className="text-lg mt-2">Please confirm to proceed.</div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-center">
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    setTruckData({
                      ...truckData,
                      active: 1
                    })
                  }}
                >
                  <div
                    className="text-base font-semibold text-unactive"
                    onClick={() => {
                      onClose()
                    }}
                  >
                    Cancel
                  </div>
                </div>
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    setTruckData({
                      ...truckData,
                      active: 0
                    })

                    sendDataUpdate(
                      {
                        truck: {
                          id: truckData.id,
                          active: 0
                        }
                      },
                      truckData.id
                    ).catch((e) => {
                      console.log('Error update status:', e.message)
                    })

                    onClose()
                  }}
                >
                  <div className="text-base font-semibold text-superbusy">
                    Disable
                  </div>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
