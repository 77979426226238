import {
  Accordion,
  AccordionItem,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Slider,
  Switch,
  useDisclosure
} from '@nextui-org/react'
import { useEffect, useRef, useState } from 'react'
import detail from '../../../../assets/button-detail.svg'
import destination from '../../../../assets/destination.svg'
import arrow from '../../../../assets/arrow.svg'
import arrowUp from '../../../../assets/arrowup.svg'
import arrowDown from '../../../../assets/arrowdown.svg'
import clock from '../../../../assets/clock.svg'
import buttonArrowLeft from '../../../../assets/button-back.svg'
import union from '../../../../assets/date.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.module.css'
import L from 'leaflet'
import {
  parserDateToString,
  sendDataUpdate,
  useTelegram
} from '../../../../fns/useTelegram'
import { BarLoader } from 'react-spinners'
import _ from 'lodash'

export const formatDate = (date) => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' }

  const dateString = `${date.toLocaleDateString('en-US', options)}`

  return dateString
}

export const formatTime = (date) => {
  const options = { hour: 'numeric', minute: 'numeric', hour12: true }

  const timeString = date.toLocaleTimeString('en-US', options)

  return timeString
}

export default function Prepare({
  selectedTruck,
  setActivePage,
  states,
  setSelectedTruckData,
  selectedTruckData
}) {
  const { tg } = useTelegram()

  const [truckData, setTruckData] = useState(selectedTruckData)

  const handleLoadTruckData = () => {
    const headers = new Headers()

    headers.append('accept', 'application/json')
    if (process.env.REACT_APP_ENV === 'production') {
      headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
    }

    fetch(
      `${process.env.REACT_APP_HOST}/api/webapp/trucks/details/${selectedTruck.id}`,
      {
        method: 'GET',
        headers: headers
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTruckData(data)
      })
      .catch((e) => {
        console.log('Error when load truck data:', e)
      })
  }

  useEffect(() => {
    !truckData && handleLoadTruckData()
  }, [])

  if (truckData === null) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999999,
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }}
      >
        <BarLoader color="#3290EC" size={20} />
      </div>
    )
  }

  return (
    <PreparePage
      truckData={truckData}
      setActivePage={setActivePage}
      defaultStates={states}
      setSelectedTruckData={setSelectedTruckData}
    />
  )
}

const PreparePage = ({
  truckData,
  setActivePage,
  defaultStates,
  setSelectedTruckData
}) => {
  const { tg } = useTelegram()

  const mapContainer = useRef()
  const mapRef = useRef()
  const radarRef = useRef()
  const markerRef = useRef()
  const containerRef = useRef()
  const datepickerRef = useRef()
  const refSelectedAll = useRef()
  const maxRef = useRef()
  const minRef = useRef()

  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onOpenChange: onOpenChangeConfirm
  } = useDisclosure()

  const isFirstRender = useRef({
    zipCode: true,
    outMiles: true
  })

  const [outMiles, setOutMiles] = useState(truckData?.truck?.radius)
  const [date, setDate] = useState(new Date(truckData.loc_from))
  const [selectStates, setSelectStates] = useState([])
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [zipCode, setZipCode] = useState(truckData?.zip || '')
  const [stateOpen, setStateOpen] = useState(false)
  const [location, setLocation] = useState(
    truckData?.lat && truckData?.lng
      ? [truckData.lat, truckData.lng]
      : [40.73307350435217, -74.00047795614519]
  )
  const [timeZone, setTimeZone] = useState(truckData.time_zone)
  const [isSelected, setIsSelected] = useState(false)
  const [states, setStates] = useState(defaultStates)
  const {
    isOpen: isOpenUpdateZipConfirm,
    onOpen: onOpenUpdateZipConfirm,
    onClose: onCloseUpdateZipConfirm
  } = useDisclosure()

  const handleUpdateLocationWhenZipcodeChanged = (zipCode) => {
    const headers = new Headers()

    headers.append('accept', 'application/json')
    if (process.env.REACT_APP_ENV === 'production') {
      headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
    }

    fetch(`${process.env.REACT_APP_HOST}/webapp/zip/${zipCode}`, {
      method: 'GET',
      headers: headers
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.lat && data.lng) {
          sendDataUpdate(
            {
              zip: zipCode,
              loc_from: parserDateToString(date)
            },
            truckData.truck.id
          )
            .then((res) => res.json())
            .then((res) => {
              setDate(new Date(res.loc_from))
            })
            .catch((e) => {
              console.log('Error zip code:', e)
            })
          setTimeZone(data.timezone)
          setLocation([data.lat, data.lng])
        }
      })
      .catch((e) => {
        console.log('Error when get location:', e)
      })
  }

  const handleLoadStates = () => {
    const headers = new Headers()

    headers.append('accept', 'application/json')
    if (process.env.REACT_APP_ENV === 'production') {
      headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
    }

    fetch(
      `${process.env.REACT_APP_HOST}/api/webapp/directions/${truckData.truck.id}`,
      {
        method: 'GET',
        headers: headers
      }
    )
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if (data.states) {
          setSelectStates(
            data.states.split(',').map((item) => parseInt(item, 10))
          )

          if (
            data.states.split(',').map((item) => parseInt(item, 10))?.length ===
            states.length
          ) {
            setIsSelected(true)
          }
        }
      })
      .catch((e) => {
        console.log('Error when load states:', e)
      })
  }

  const handleSelectState = (index, status) => {
    setSelectStates((prev) => {
      if (status) {
        prev.push(index)
      } else {
        prev = prev.filter((item) => item != index)
      }

      const headers = new Headers()

      headers.append('accept', 'application/json')
      headers.append('Content-Type', 'application/json')

      if (process.env.REACT_APP_ENV === 'production') {
        headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
      }

      fetch(
        `${process.env.REACT_APP_HOST}/api/webapp/directions/update?truck_id=${
          truckData.truck.id
        }&states=${prev.join(',')}&all=false`,
        {
          method: 'POST',
          headers: headers
        }
      )
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          console.log(data)
        })
        .catch((e) => {
          console.log('Error when update states:', e)
        })

      return [...prev]
    })
  }

  const handleSelectAllStates = (status) => {
    setSelectStates((prev) => {
      let selectedState = ''

      if (status) {
        setIsSelected(true)

        prev = states.map((item, index) => item.id)

        selectedState = prev.join(',')
      } else {
        setIsSelected(false)
        prev = []
      }

      const headers = new Headers()

      headers.append('accept', 'application/json')
      headers.append('Content-Type', 'application/json')

      if (process.env.REACT_APP_ENV === 'production') {
        headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
      }

      fetch(
        `${process.env.REACT_APP_HOST}/api/webapp/directions/update?truck_id=${
          truckData.truck.id
        }&states=${selectedState}&all=${status ? 'true' : 'false'}`,
        {
          method: 'POST',
          headers: headers
        }
      )
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          console.log(data)
        })
        .catch((e) => {
          console.log('Error when update states:', e)
        })
        .finally(() => {
          setStates(JSON.parse(JSON.stringify(states)))
        })

      return [...prev]
    })
  }

  useEffect(() => {
    if (isFirstRender.current.zipCode) {
      isFirstRender.current.zipCode = false
      return
    }

    if (process.env.REACT_APP_TYPE !== 'paid' && zipCode?.length === 5) {
      handleUpdateLocationWhenZipcodeChanged(zipCode)
    }
  }, [zipCode])

  useEffect(() => {
    if (!mapContainer.current) return

    let map = L.map(mapContainer.current, {
      attributionControl: false,
      zoomControl: false,
      zoomAnimation: true
    }).setView(location, 7)

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      updateWhenIdle: true,
      updateWhenZooming: true,
      updateInterval: 200
    }).addTo(map)

    const dIcon = L.icon({
      iconUrl: destination,
      iconSize: [50, 50],
      popupAnchor: [0, -25],
      shadowSize: [68, 55],
      shadowAnchor: [22, 55]
    })

    const marker = L.marker(location, {
      alt: '',
      icon: dIcon
    })
      .addTo(map)
      .bindPopup(
        `<div class="text-xs font-semibold text-black text-center">${truckData.driver}</div>`
      )
      .openPopup()

    markerRef.current = marker

    const radar = L.circle(marker.getLatLng(), {
      radius: outMiles * 1609.34,
      className: 'zoom',
      stroke: '#55bf3b'
    }).addTo(map)

    radarRef.current = radar

    radar.setStyle({
      color: '#55bf3b'
    })

    mapRef.current = map

    const zoom = mapRef.current.getBoundsZoom(radarRef.current.getBounds())

    mapRef.current.flyTo(location, zoom)

    return () => map.remove()
  }, [mapContainer])

  useEffect(() => {
    markerRef.current.setLatLng(location)
    radarRef.current.setLatLng(location)

    const zoom = mapRef.current.getBoundsZoom(radarRef.current.getBounds())

    mapRef.current.flyTo(location, zoom)

    markerRef.current.openPopup()
  }, [location])

  useEffect(() => {
    if (isFirstRender.current.outMiles) {
      isFirstRender.current.outMiles = false
      return
    }

    const update = setTimeout(() => {
      if (radarRef.current && mapRef.current) {
        sendDataUpdate(
          {
            truck: {
              id: truckData.truck.id,
              radius: outMiles
            }
          },
          truckData.truck.id
        ).catch((e) => {
          console.log('Error when update radius:', e)
        })
        radarRef.current && radarRef.current.setRadius(outMiles * 1609.34)
        const zoom = mapRef.current.getBoundsZoom(radarRef.current.getBounds())

        mapRef.current.setView(location, zoom)
      }
    }, 200)

    return () => clearTimeout(update)
  }, [outMiles])

  useEffect(() => {
    if (stateOpen) {
      setTimeout(() => {
        if (refSelectedAll.current) {
          refSelectedAll.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }
      }, 300)
    }
  }, [stateOpen])

  useEffect(() => {
    if (showDatePicker) {
      if (datepickerRef.current) {
        setTimeout(() => {
          datepickerRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
          })
        }, 100)
      }
    }
  }, [showDatePicker])

  useEffect(() => {
    handleLoadStates()
  }, [])

  return (
    <>
      <div
        className="flex flex-col flex-1 place-content-start overflow-y-scroll"
        ref={containerRef}
      >
        <div className="">
          <div className="w-full h-[332px]">
            <div
              className="w-full h-full"
              ref={(el) => (mapContainer.current = el)}
              onTouchEnd={() => {
                setTimeout(() => {
                  try {
                    mapRef.current && mapRef.current.invalidateSize(true)
                  } catch (e) {}
                }, 100)
              }}
            >
              <img
                src={detail}
                className="h-11 w-11 mt-1/2 absolute right-0 z-[999] active:opacity-50"
                style={{
                  top: 'calc(50% - 20px)'
                }}
                onClick={() => {
                  setSelectedTruckData(JSON.parse(JSON.stringify(truckData)))
                  setActivePage(2)
                }}
              />

              <img
                src={buttonArrowLeft}
                className="w-11 h-11 absolute left-0 z-[999] active:opacity-50 top-1"
                onClick={() => {
                  setSelectedTruckData(null)
                  setActivePage(0)
                }}
              />
            </div>
          </div>
        </div>
        <div className="px-4 mt-12">
          <Slider
            size="sm"
            classNames={{
              base: 'w-full',
              inputWrapper: 'bg-white shadow-none',
              thumb: 'bg-primary'
            }}
            defaultValue={outMiles}
            onChange={setOutMiles}
            minValue={50}
            maxValue={500}
            renderThumb={(props) => (
              <div
                {...props}
                className={`${props.className} bg-primary after:bg-primary relative`}
                data-slot="thumb"
                data-focused="true"
                orientation="horizontal"
                index="0"
              >
                <div
                  className="absolute -top-10 bg-primary top text-sm p-1 text-white rounded-lg text-center font-bold whitespace-nowrap"
                  style={{
                    left: outMiles < 100 ? -10 : outMiles > 450 ? -50 : 'unset'
                  }}
                >
                  Out: {outMiles}mi
                  <div
                    className={`absolute -bottom-2 transform -translate-x-1/2 w-0 h-0 border-t-8 border-t-primary border-l-8 border-transparent border-r-8`}
                    style={{
                      left: outMiles < 100 ? 20 : outMiles > 450 ? 60 : '50%'
                    }}
                  ></div>
                </div>
              </div>
            )}
          />
          <div className="flex flex-row items-center justify-between relative -top-8">
            <div className="text-xs text-placeholder font-medium">{50}</div>
            <div className="text-xs text-placeholder font-medium">{500}</div>
          </div>
        </div>
        <div className="px-4 flex-1 basis-0 -top-5 relative">
          <div>
            <div>
              <div className="text-base text-black opacity-50 mt-3">Zip</div>
              <Input
                classNames={{
                  base: 'mt-2 h-12',
                  inputWrapper: [
                    'rounded-md',
                    'border-1',
                    'shadow-none',
                    'bg-white',
                    'group-data-[focus=true]:bg-white',
                    'group-data-[hover=true]:bg-white',
                    'py-6',
                    'h-12'
                  ],
                  input: [
                    'font-normal',
                    '!text-base',
                    'text-text-input-color',
                    'py-6',
                    'h-12'
                  ]
                }}
                type="number"
                defaultValue={zipCode}
                placeholder="Current Location (ZIP)"
                endContent={<img src={arrow} />}
                onChange={(e) => {
                  setZipCode(e.target.value + '')
                }}
              />
              {process.env.REACT_APP_TYPE === 'paid' && (
                <Button
                  color="primary"
                  className="mt-3 w-full rounded-md h-12"
                  disabled={zipCode?.length !== 5}
                  onClick={() => {
                    onOpenUpdateZipConfirm()
                  }}
                >
                  <div className="text-white text-lg">Submit</div>
                </Button>
              )}
            </div>
            <div className="text-base text-black opacity-50 mt-4">Date</div>
            <div>
              <div
                className="border-1 border-solid border-border-color p-3 rounded-md mt-2 flex items-center justify-between active:opacity-50 h-12"
                onClick={() => setShowDatePicker(!showDatePicker)}
              >
                <div className="flex">
                  <div className="flex items-center">
                    <img src={union} />
                    <div className="text-base text-black font-normal ml-1">
                      {formatDate(date)}
                    </div>
                  </div>
                  <div className="flex items-center ml-5">
                    <img src={clock} />
                    <div className="text-base text-black font-normal ml-1">
                      {formatTime(date)}
                    </div>
                  </div>
                </div>

                <img
                  src={showDatePicker ? arrowUp : arrowDown}
                  className="relative"
                />
              </div>
              {showDatePicker && (
                <div className="flex justify-end mt-1">
                  <div className="" ref={datepickerRef}>
                    <DatePicker
                      selected={date}
                      onChange={(currentDate) => {
                        setDate(currentDate)
                        sendDataUpdate(
                          {
                            zip: zipCode,
                            loc_from: parserDateToString(new Date(currentDate))
                          },
                          truckData.truck.id
                        ).catch((e) => {
                          console.log('Error when update date:', e)
                        })
                      }}
                      selectsDisabledDaysInRange
                      inline
                      showTimeSelect
                      timeFormat="HH:mm"
                      calendarClassName="w-[calc(100vw-32px)]"
                    >
                      <div>Time Zone: {timeZone}</div>
                    </DatePicker>
                  </div>
                </div>
              )}
            </div>
            <div className="text-base text-black opacity-50 mt-4">
              Total Miles
            </div>
            <div className="grid grid-cols-2 gap-4 mt-2 mb-2">
              <Input
                classNames={{
                  base: 'h-12',
                  inputWrapper: [
                    'rounded-md',
                    'border-1',
                    'shadow-none',
                    'bg-white',
                    'group-data-[focus=true]:bg-white',
                    'group-data-[hover=true]:bg-white',
                    'py-6',
                    'h-12'
                  ],
                  input: [
                    'font-normal',
                    '!text-sm',
                    'text-text-input-color',
                    'py-6',
                    'h-12'
                  ]
                }}
                onChange={(e) => {
                  clearTimeout(minRef.current)

                  minRef.current = setTimeout(() => {
                    sendDataUpdate(
                      {
                        truck: {
                          id: truckData.truck.id,
                          distance_min: parseInt(e.target.value) || 0
                        }
                      },
                      truckData.truck.id
                    ).catch((e) => {
                      console.log('Error when update distance min:', e)
                    })
                  }, 500)
                }}
                defaultValue={truckData?.truck?.distance_min}
                startContent={
                  <div className="text-sm text-placeholder font-medium">
                    Min
                  </div>
                }
                endContent={'mi'}
              />
              <Input
                classNames={{
                  base: 'h-12',
                  inputWrapper: [
                    'rounded-md',
                    'border-1',
                    'shadow-none',
                    'bg-white',
                    'group-data-[focus=true]:bg-white',
                    'group-data-[hover=true]:bg-white',
                    'py-6',
                    'h-12'
                  ],
                  input: [
                    'font-normal',
                    '!text-sm',
                    'text-text-input-color',
                    'py-6',
                    'h-12'
                  ]
                }}
                onChange={(e) => {
                  clearTimeout(minRef.current)

                  minRef.current = setTimeout(() => {
                    sendDataUpdate(
                      {
                        truck: {
                          id: truckData.truck.id,
                          distance_max: parseInt(e.target.value) || 0
                        }
                      },
                      truckData.truck.id
                    ).catch((e) => {
                      console.log('Error when update distance max:', e)
                    })
                  }, 500)
                }}
                defaultValue={truckData?.truck?.distance_max}
                startContent={
                  <div className="text-sm text-placeholder font-medium">
                    Max
                  </div>
                }
                endContent={'mi'}
              />
            </div>
            <div
              className="bg-white"
              style={{
                position: stateOpen ? 'fixed' : 'relative',
                height: stateOpen ? '100vh' : 'auto',
                width: stateOpen ? '100vw' : 'auto',
                zIndex: stateOpen ? 1000 : 0,
                top: 0,
                left: 0
              }}
            >
              <Accordion>
                <AccordionItem
                  disableIndicatorAnimation={true}
                  title={
                    <div className="text-base text-black font-medium">
                      Select State(s)
                    </div>
                  }
                  indicator={({ isOpen }) => {
                    setStateOpen(isOpen)

                    return isOpen ? (
                      <img src={arrowUp} />
                    ) : (
                      <img src={arrowDown} />
                    )
                  }}
                  classNames={{
                    content: 'overflow-y-scroll h-[calc(100vh-56px)]'
                  }}
                >
                  <div className="grid grid-cols-1 gap-3">
                    <div
                      className="flex items-center justify-between pb-5 border-b-1 border-border-color gap-3"
                      key={Math.random()}
                      ref={refSelectedAll}
                    >
                      <div className="flex items-center justify-center gap-2">
                        <div className="text-base font-medium text-black">
                          {'Select All'}
                        </div>
                        {/* <div
                            className={`bg-superbusy bg-opacity-25 text-superbusy text-sm font-bold px-3 py-1 rounded flex-grow-0 min-w-[101px]`}
                          >
                            Super Busy
                          </div> */}
                      </div>
                      <div>
                        <Switch
                          defaultSelected={isSelected}
                          color="primary"
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleSelectAllStates(true)
                            } else {
                              onOpenConfirm()
                            }
                          }}
                        />
                      </div>
                    </div>
                    {states.map((item, index) => {
                      return (
                        <div
                          className="flex items-center justify-between pb-5 border-b-1 border-border-color gap-3"
                          key={item.id + Math.random()}
                        >
                          <div className="flex items-center justify-center gap-2">
                            <div className="text-base font-medium text-black">
                              {item.name}
                            </div>
                            {/* <div
                            className={`bg-superbusy bg-opacity-25 text-superbusy text-sm font-bold px-3 py-1 rounded flex-grow-0 min-w-[101px]`}
                          >
                            Super Busy
                          </div> */}
                          </div>
                          <div>
                            <Switch
                              defaultSelected={selectStates.includes(item.id)}
                              color="primary"
                              onChange={(e) => {
                                console.log(item)
                                handleSelectState(item.id, e.target.checked)
                              }}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpenConfirm}
        onOpenChange={onOpenChangeConfirm}
        placement="center"
        classNames={{
          wrapper: 'px-5 z-[1001]',
          closeButton: 'mt-2',
          base: 'rounded-md',
          backdrop: 'z-[1001]'
        }}
        closeButton={<></>}
        scrollBehavior={'inside'}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="text-center border-b-1">
                <div className="pt-9 pb-4">
                  <div className="text-lg">
                    Are you sure you want to deselect all states?
                  </div>
                  <div className="text-lg mt-2">Please confirm to proceed.</div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-center">
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    setIsSelected(true)
                  }}
                >
                  <div
                    className="text-base font-semibold text-unactive"
                    onClick={() => {
                      onClose()
                    }}
                  >
                    Cancel
                  </div>
                </div>
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    handleSelectAllStates(false)

                    onClose()
                  }}
                >
                  <div className="text-base font-semibold text-superbusy">
                    Disable
                  </div>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenUpdateZipConfirm}
        onOpenChange={onOpenUpdateZipConfirm}
        placement="center"
        classNames={{
          wrapper: 'px-5 z-[1001]',
          closeButton: 'mt-2',
          base: 'rounded-md',
          backdrop: 'z-[1001]'
        }}
        closeButton={<></>}
        scrollBehavior={'inside'}
      >
        <ModalContent>
          {() => (
            <>
              <ModalBody className="text-center border-b-1">
                <div className="pt-9 pb-4">
                  <div className="text-lg text-black font-bold">
                    Please confirm the update
                  </div>
                  <div className="text-base mt-2">
                    Updating the zip code will charge $
                    {process.env.REACT_APP_COST} to your account
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-center">
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    setIsSelected(true)
                  }}
                >
                  <div
                    className="text-base font-semibold text-unactive"
                    onClick={() => {
                      onCloseUpdateZipConfirm()
                    }}
                  >
                    Cancel
                  </div>
                </div>
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    if (zipCode?.length === 5) {
                      handleUpdateLocationWhenZipcodeChanged(zipCode)
                    }

                    onCloseUpdateZipConfirm()
                  }}
                >
                  <div className="text-base font-semibold text-primary">
                    Update
                  </div>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
