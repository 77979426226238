/** @format */

import L from 'leaflet'
import '../../fns/L.Path.DashFlow'
import 'leaflet-rotatedmarker'
import { useEffect, useRef } from 'react'
import { getVehicleMarker } from '../../fns/vehicleMarker'
import { addRoute } from '../../fns/addRoute'
import { addPickupDelivery } from '../../fns/addPickupDelivery'
import detail from '../../assets/detail.png'
import arrowDown from '../../assets/arrow-down.svg'
import arrowUp from '../../assets/arrow-up.svg'
import { useState } from 'react'
import 'leaflet-geometryutil'
import 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import border from '../../assets/border.svg'
import box from '../../assets/box.svg'
import { renderToString } from 'react-dom/server'
import star from '../../assets/star.svg'

const FILTER = [
  {
    label: 'Current',
    value: 0
  },
  {
    label: 'Yesterday',
    value: 1
  },
  {
    label: 'Last week',
    value: 7
  }
]

function getColorFromQuantity(quantity, minQuantity, maxQuantity) {
  if (quantity === 0 || maxQuantity - minQuantity === 0) {
    return '#B2B2B2'
  }

  // Calculate percentage
  const percentage =
    ((quantity - minQuantity) / (maxQuantity - minQuantity)) * 100

  // Determine color range and calculate ratio
  let color1, color2, rangeMin, rangeMax

  if (percentage < 29) {
    color1 = '#2088FF'
    color2 = '#89BAF4'
    rangeMin = 0
    rangeMax = 29
  } else if (percentage < 49) {
    color1 = '#89BAF4'
    color2 = '#BDDCEF'
    rangeMin = 29
    rangeMax = 49
  } else if (percentage < 73) {
    color1 = '#BDDCEF'
    color2 = '#FFBDB9'
    rangeMin = 49
    rangeMax = 73
  } else {
    color1 = '#FFBDB9'
    color2 = '#F56B6A'
    rangeMin = 73
    rangeMax = 98
  }

  // Calculate ratio within the specific range
  const ratio = (percentage - rangeMin) / (rangeMax - rangeMin)

  // Interpolate color
  return interpolateColor(color1, color2, ratio)
}

function interpolateColor(color1, color2, ratio) {
  let r1 = parseInt(color1.substring(1, 3), 16)
  let g1 = parseInt(color1.substring(3, 5), 16)
  let b1 = parseInt(color1.substring(5, 7), 16)

  let r2 = parseInt(color2.substring(1, 3), 16)
  let g2 = parseInt(color2.substring(3, 5), 16)
  let b2 = parseInt(color2.substring(5, 7), 16)

  let r = Math.round(r1 + (r2 - r1) * ratio)
  let g = Math.round(g1 + (g2 - g1) * ratio)
  let b = Math.round(b1 + (b2 - b1) * ratio)

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}

export function LiveMapContainer({ orderInfo, switchViews }) {
  const mapContainer = useRef()
  const mapRef = useRef()
  const radarRef = useRef()
  const labelRef = useRef()

  const [locState, setLocState] = useState(null)
  const [totalLoads, setTotalLoads] = useState(0)
  const [collapse, setCollapse] = useState(false)

  const loadNearest = async () => {
    if (!orderInfo?.delivery) return

    const res = await fetch(
      `${
        process.env.REACT_APP_HOST
      }/api/webapp/directions/loc_stat/${orderInfo.delivery.address
        ?.split(' ')
        .pop()}/${orderInfo.truck.vin}`
    ).then((res) => res.json())

    const dataValue = Object.entries(res.locs).map(([key, value]) => value)

    const min = Math.min(...dataValue)
    const max = Math.max(...dataValue)

    const data = {
      labels: Object.entries(res.locs).map(([key, value]) =>
        key.split(' ').pop()
      ),
      datasets: [
        {
          data: dataValue,
          backgroundColor: dataValue.map((value) =>
            getColorFromQuantity(value, min, max)
          ),
          borderWidth: 0
        }
      ]
    }

    setLocState(data)

    const radarColor = getColorFromQuantity(res.max_loads.qty, min, max)

    radarRef.current.setStyle({
      color: radarColor
    })

    labelRef.current.remove && labelRef.current.remove()

    const radiusInMeters = 200 * 1609.34

    // Calculate label position (simplified for demonstration)
    // This is a naive approach and might not work perfectly for all zoom levels or map projections
    const labelLatLng = L.GeometryUtil.destination(
      radarRef.current.getLatLng(),
      45,
      radiusInMeters,
      mapRef.current
    )

    const labelContent = `<div style="background-color: ${radarColor}; padding: 2px 5px; border-radius: 3px; color: #FFFFFF; text-align: center;" >200 mi</div>`
    const labelIcon = L.divIcon({
      className: 'custom-label', // custom class for CSS styling
      html: labelContent,
      iconSize: [60, 20]
    })

    labelRef.current = labelIcon

    L.marker(labelLatLng, { icon: labelIcon }).addTo(mapRef.current)

    const boxIcon = L.divIcon({
      html: renderToString(
        <div
          style={{
            padding: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={border}
            style={{
              width: `45px`,
              height: `45px`
            }}
          />
          <img
            src={box}
            className="absolute"
            style={{
              padding: `4px`,
              backgroundColor: radarColor,
              borderRadius: '100%',
              width: `35px`,
              height: `35px`
            }}
          />
          <img src={star} className="absolute -top-0 -right-5" />
        </div>
      ),
      className: '' // Add any custom class if needed
    })

    L.marker([res.max_loads.lat, res.max_loads.lng], {
      icon: boxIcon
    })
      .addTo(mapRef.current)
      .bindPopup(
        renderToString(
          <div>
            <div className="text-center">Zip: {res.max_loads?.zip}</div>
            <div className="text-center">Loads: {res.max_loads?.qty}pcs</div>
            <div className="text-center">Dist: {res.max_loads?.dist}mi</div>
          </div>
        )
      )

    setTotalLoads(dataValue.reduce((a, b) => a + b, 0))
  }

  useEffect(() => {
    let map = L.map(mapContainer.current, {
      attributionControl: false,
      zoomControl: true,
      zoomAnimation: true
    }).setView(
      [
        orderInfo?.truck?.loc[0] || 40.73307350435217,
        orderInfo?.truck?.loc[1] || -74.00047795614519
      ],
      mapRef.current?.getZoom() || 5
    )

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      updateWhenIdle: true,
      updateWhenZooming: true,
      updateInterval: 200
    }).addTo(map)

    getVehicleMarker(orderInfo, map)

    addRoute(orderInfo, map)

    addPickupDelivery(orderInfo, map)

    if (orderInfo?.truck && orderInfo?.pickup && orderInfo?.delivery) {
      const radar = L.circle(orderInfo.delivery.loc, {
        radius: 200 * 1609.34,
        className: 'zoom relative',
        stroke: '#55bf3b'
      }).addTo(map)

      radarRef.current = radar

      radar.setStyle({
        color: '#55bf3b'
      })

      const zoom = map.getBoundsZoom(radar.getBounds())

      const radiusInMeters = 200 * 1609.34

      // Calculate label position (simplified for demonstration)
      // This is a naive approach and might not work perfectly for all zoom levels or map projections
      const labelLatLng = L.GeometryUtil.destination(
        radar.getLatLng(),
        45,
        radiusInMeters,
        map
      )

      // Create a custom label as a marker
      const labelContent = `<div style="background-color: #24A841; padding: 2px 5px; border-radius: 3px; color: #FFFFFF; text-align: center;" >200 mi</div>`
      const labelIcon = L.divIcon({
        className: 'custom-label', // custom class for CSS styling
        html: labelContent,
        iconSize: [60, 20]
      })

      labelRef.current = labelIcon

      L.marker(labelLatLng, { icon: labelIcon }).addTo(map)

      map.setView(orderInfo.delivery.loc, zoom)
    }

    mapRef.current = map

    window.addEventListener('resize', () => {
      setTimeout(() => {
        try {
          console.log('Revalidate map when resize')

          mapRef.current.invalidateSize(true)
        } catch (e) {}
      }, 100)
    })

    mapRef.current.whenReady(() => {
      setTimeout(() => {
        try {
          mapRef.current.invalidateSize(true)
          console.log('Revalidate map when ready')
        } catch (e) {}
      }, 500)
    })

    const renderLoadNearest = setTimeout(async () => {
      await loadNearest()
    }, 0)

    setTimeout(function () {
      window.dispatchEvent(new Event('resize'))
    }, 1000)

    return () => {
      map.remove()

      clearTimeout(renderLoadNearest)

      window.removeEventListener('resize', () => {})
    }
  }, [orderInfo])

  return (
    <>
      <div
        style={{
          width: '100vw',
          height: 'calc(100vh - 142px)'
        }}
        className="relative"
      >
        <div
          style={{
            width: '100vw',
            height: 'calc(100vh - 147px)',
            zIndex: 1
          }}
          ref={(el) => (mapContainer.current = el)}
          onTouchEnd={() => {
            setTimeout(() => {
              try {
                mapRef.current.invalidateSize(true)
              } catch (e) {}
            }, 100)
          }}
        ></div>

        <div className="absolute z-50 top-2 right-3 bg-white rounded-lg shadow-large">
          <div
            className="p-2 flex flex-row justify-between"
            onClick={() => {
              setCollapse(!collapse)
            }}
          >
            <div className="text-xs font-medium">
              Last Week:{' '}
              {!totalLoads ? (
                'No Data'
              ) : (
                <>
                  <span className="font-bold">{totalLoads}</span> Loads
                </>
              )}
            </div>
            <img src={collapse ? arrowDown : arrowUp} className="ml-4" />
          </div>
          {locState && collapse && (
            <Bar
              data={locState}
              options={{
                plugins: {
                  legend: {
                    display: false
                  }
                },
                scales: {
                  x: {
                    grid: {
                      display: false // Disable x-axis grid lines
                    },
                    ticks: {
                      maxRotation: 0, // Disable label rotation
                      minRotation: 0, // Disable label rotation
                      font: {
                        family: 'Inter-Regular',
                        weight: '500',
                        size: 10
                      }
                    }
                  },
                  y: {
                    grid: {
                      display: false // Disable y-axis grid lines
                    }
                  }
                },
                layout: {
                  padding: {
                    left: 10,
                    right: 10
                  }
                }
              }}
            />
          )}
        </div>

        <img
          src={detail}
          className="nav-icon"
          onClick={() => {
            switchViews(1)
          }}
        />
      </div>
    </>
  )
}
