import { useEffect, useState, useRef } from 'react'
import Detail from './Detail/Detail'
import Prepare from './Prepare/Prepare'
import Title from './Title/Title'
import TruckPage from './TrucksPage/TruckPage'
import { useTelegram } from '../../../fns/useTelegram'

export default function ClientPage() {
  const [states, setStates] = useState()
  const [trucks, setTrucks] = useState(null)
  const [activePage, setActivePage] = useState(0)
  const [selectedTruck, setSelectedTruck] = useState(null)
  const [selectedTruckData, setSelectedTruckData] = useState(null)

  const { tg } = useTelegram()

  const handleMapActive = (truckData, truck) => {
    if (truck.active === 0) {
      return 1
    }

    switch (truckData.upd_status?.toLowerCase()) {
      case 'updated': {
        return 2
      }

      case 'disabled': {
        return 3
      }

      case 'not updated': {
        return 4
      }
    }

    return 0
  }

  const handleLoadTrucks = () => {
    const headers = new Headers()

    headers.append('accept', 'application/json')
    if (process.env.REACT_APP_ENV === 'production') {
      headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
    }

    fetch(`${process.env.REACT_APP_HOST}/api/webapp/trucks`, {
      method: 'GET',
      headers: headers
    })
      .then((res) => res.json())
      .then(async (data) => {
        const states = data.states
        setStates(states)

        let truckList = data.truck_list

        truckList = truckList.sort((a, b) => {
          const pointA = handleMapActive(a, a)

          const pointB = handleMapActive(b, b)

          return -(pointA - pointB)
        })

        setTrucks(JSON.parse(JSON.stringify(truckList)))

        setSelectedTruck((pre) => {
          return JSON.parse(
            JSON.stringify(
              truckList.find((item) => item?.truck_id === pre?.truck_id) || null
            )
          )
        })
      })
      .catch((e) => {
        console.log('Error when get trucks:', e)
        setTrucks([])
      })
  }

  useEffect(() => {
    handleLoadTrucks()
  }, [activePage])

  return (
    <div className="flex flex-col w-svw h-svh flex-1 bg-background">
      {activePage !== 1 && (
        <Title
          activePage={activePage}
          setActivePage={setActivePage}
          setSelectedTruck={setSelectedTruck}
          selectedTruck={selectedTruck}
        />
      )}
      {activePage == 0 ? (
        <TruckPage
          key={Math.random()}
          trucks={trucks}
          setActivePage={setActivePage}
          setSelectedTruck={setSelectedTruck}
        />
      ) : activePage == 1 ? (
        <>
          {selectedTruck && (
            <Prepare
              selectedTruck={selectedTruck}
              setActivePage={setActivePage}
              states={states}
              setSelectedTruckData={setSelectedTruckData}
              selectedTruckData={selectedTruckData}
            />
          )}
        </>
      ) : (
        <Detail truckData={selectedTruckData} />
      )}
    </div>
  )
}
