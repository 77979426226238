import { Switch } from '@nextui-org/react'
import { sendDataUpdate } from '../../../../fns/useTelegram'

const Section = ({ title, value }) => (
  <div className="flex flex-row pb-5 border-b-1 border-border-color justify-between">
    <div className="text-base text-black text-opacity-55 font-medium">
      {title}
    </div>
    {typeof value === 'string' ? (
      <div className="text-base text-black text-opacity-55 font-semibold capitalize">
        {value}
      </div>
    ) : (
      value
    )}
  </div>
)

export default function Detail({ truckData }) {
  return (
    <div className="grid grid-cols-1 px-8 overflow-y-scroll flex-1 place-content-start mt-5 gap-5">
      <Section title={'Driver'} value={truckData?.driver} />
      <Section
        title={'Border'}
        value={
          <Switch
            color="primary"
            onChange={(e) => {
              sendDataUpdate(
                {
                  truck: {
                    id: truckData.truck.id,
                    border_access: e.target.checked ? 1 : 0
                  }
                },
                truckData.truck.id
              )
            }}
            defaultSelected={!!truckData?.truck?.border_access}
          />
        }
      />
      <Section
        title={'Citizen'}
        value={truckData?.truck?.is_us_citizen == 0 ? 'No' : 'Yes'}
      />
      <Section
        title={'Truck Dimensions'}
        value={`${truckData?.truck?.length}L x ${truckData?.truck?.width}W x${truckData?.truck?.height}H`}
      />
      <Section
        title={'Door Dimensions'}
        value={`${truckData?.truck?.door_width}W x ${truckData?.truck?.door_height}H`}
      />
      <Section title={'Weight'} value={`${truckData?.truck?.weight} lbs`} />
      <Section title={'Identifier'} value={truckData?.truck.identifier} />
      <Section title={'Plate Number'} value={truckData?.truck.plate_number} />
      <Section title={'VIN Number'} value={truckData?.truck.vin} />
      <Section title={'Model'} value={truckData?.truck?.model} />
      <Section title={'Brand'} value={truckData?.truck?.brand} />
      <Section title={'Year'} value={truckData?.truck?.year + ''} />
      <Section title={'Type'} value={truckData?.type?.toLowerCase()} />
      <Section
        title={'Refrigerated'}
        value={truckData?.truck?.is_refrigerated == 0 ? 'No' : 'Yes'}
      />
      <Section
        title={'Cargo Area Type'}
        value={
          truckData?.truck?.cargo_area_type_id == 2 ? 'Hard Box' : 'Curtainside'
        }
      />
      <Section
        title={'Lift Gate'}
        value={
          <Switch
            color="primary"
            onChange={(e) => {
              sendDataUpdate(
                {
                  truck: {
                    id: truckData.truck.id,
                    lift_gate: e.target.checked ? 1 : 0
                  }
                },
                truckData.truck.id
              )
            }}
            defaultSelected={!!truckData?.truck?.lift_gate}
          />
        }
      />
    </div>
  )
}
