/** @format */

import L from 'leaflet'
import destination from '../assets/destination.svg'
import packageSvg from '../assets/package.svg'

export function addPickupDelivery(orderData, map) {
  const pIcon = L.icon({
    iconUrl: packageSvg,
    iconSize: [50, 50],
    popupAnchor: [0, -25],
    shadowSize: [68, 55],
    shadowAnchor: [22, 55]
  })

  const dIcon = L.icon({
    iconUrl: destination,
    iconSize: [50, 50],
    popupAnchor: [0, -25],
    shadowSize: [68, 55],
    shadowAnchor: [22, 55]
  })

  if (orderData?.pickup?.loc) {
    L.marker(orderData?.pickup?.loc ? orderData?.pickup?.loc : [0, 0], {
      alt: orderData?.pickup?.address ? orderData.pickup.loc : '',
      icon: pIcon
    })
      .addTo(map)
      .bindPopup(
        `Pick Up:<br/>${
          orderData?.pickup?.address ? orderData.pickup.address : ''
        }<br/>Out: ${orderData?.order_data?.out_miles} Miles`
      )
  }

  if (orderData?.delivery?.loc) {
    const pic = L.marker(
      orderData?.delivery?.loc ? orderData.delivery.loc : [0, 0],
      {
        alt: orderData?.delivery?.address ? orderData.delivery.address : '',
        icon: dIcon
      }
    )
      .addTo(map)
      .bindPopup(
        `Delivery:<br/>${
          orderData?.delivery?.address ? orderData.delivery.address : ''
        }<br/>Est. Miles : ${orderData?.order_data.estimate_miles}`
      )
      .openPopup()
  }

  if (orderData?.truck?.loc && orderData?.pickup?.loc) {
    const bounds = L.latLngBounds(
      orderData?.truck?.loc ? orderData.truck.loc : [0, 0],
      orderData?.pickup?.loc ? orderData.pickup.loc : [0, 0]
    )
  }

  return map
}
