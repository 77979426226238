import { Switch } from '@nextui-org/react'
import back from '../../../../assets/back.svg'
import { sendDataUpdate } from '../../../../fns/useTelegram'

export default function Title({
  activePage,
  setActivePage,
  setSelectedTruck,
  selectedTruck
}) {
  return (
    <div className="flex justify-between items-center py-2 mt-3 px-4">
      <div className="active:opacity-50">
        <img
          src={back}
          onClick={() => {
            if (activePage == 1) {
              setActivePage(0)
              setSelectedTruck(null)
            } else if (activePage == 2) {
              setActivePage(1)
            } else if (activePage === 0) {
              window.location.assign(
                `${process.env.REACT_APP_DASHBOARD}${location.search}`
              )
            }
          }}
        />
      </div>
      <div className="text-lg font-bold text-black">
        {activePage == 0 ? 'Truck Page' : selectedTruck?.driver}
      </div>
      <div className="">
        {activePage != 0 && (
          <Switch
            defaultSelected={!!selectedTruck?.status || false}
            color="primary"
            onChange={(e) => {
              sendDataUpdate(
                {
                  truck: {
                    id: selectedTruck.truck.id,
                    status: e.target.checked ? 1 : 0
                  }
                },
                selectedTruck.truck.id,
                ''
              ).catch((e) => {
                console.log('Error update status:', e.message)
              })
            }}
          />
        )}
      </div>
    </div>
  )
}
