/** @format */

import { useEffect } from "react";
//import { useTelegram } from "./fns/useTelegram";
import { useTelegram } from "./fns/useTelegram";
import { AppWrapper } from "./Components/AppWrapper";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./Components/NotFound";
import RouterApp from './Components/TruckManagerComponents/Router'

function App() {
  const { expand, tg } = useTelegram();

  useEffect(() => {
    expand();
  }, []);

  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<RouterApp/>} />
        <Route path="/bidder" element={<AppWrapper />} />      
        <Route path="*" element={<NotFound />} />        
      </Routes>
    </Router>
  );    
}

export default App;
